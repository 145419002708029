/* tslint:disable:max-line-length */
import { KodNavigationItem } from '@kod/components/navigation';

export const appNavigation: KodNavigationItem[] = [
  {
    id : 'd.og.shares',
    title : 'Services',
    type : 'basic',
    icon : 'heroicons_outline:server-stack',
    link : '/services'
  },
  ];

export const compactNavigation: KodNavigationItem[] = [
...appNavigation
];
export const futuristicNavigation: KodNavigationItem[] = [
...appNavigation
];
export const horizontalNavigation: KodNavigationItem[] = [
...appNavigation
];
export const defaultNavigation: KodNavigationItem[] = [
...appNavigation
];
